* {
  box-sizing: border-box;
}
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img,
a {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000;
  font-size: 1em;
  word-break: keep-all;
  word-wrap: break-word;
}
html,
body {
  font-size: 16px;
  width: 100%;
  height: 100% !important;
  overflow-x: hidden;
  overscroll-behavior: contain;
}
body.active {
  overflow-y: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: 0;
}
button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}
input,
select,
textarea {
  border: 1px solid #aaa;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-pagination-options {
  display: none;
}

.drag {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

.scrollTable{height: 100%;overflow-x: auto;width: 100%;overflow-y: hidden;}